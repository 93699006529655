// 
// tables.scss
//


//Table centered (Custom)
.table-centered {
    td,th {
        vertical-align: middle !important;
    }
}

.table-striped {
    thead tr, tbody tr {
        background-color: $card-bg;
    }
}