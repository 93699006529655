// 
// authentication.scss
//

.authentication-bg.enlarged {
    min-height: 100px;
}

// authentication pages background
body.authentication-bg {
    background-image: url($authentication-bg);
    background-size: cover;
    background-position: center;
}

.home-btn {
    position: absolute;
    top: 15px;
    right: 25px;
}

// Logout page
.logout-icon {
    width: 140px;
}

.g-recaptcha div {
    margin: auto;
}

.login-logo {
    height: $authentication-logo-height;
    width: $authentication-logo-width;
}